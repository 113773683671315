// import React, { useState, useEffect } from 'react';
// import { motion } from 'framer-motion';
// import { db } from '../../firebase';
// import { collection, query, orderBy, getDocs } from 'firebase/firestore';

const VideoPresence = () => {
  // const [videos, setVideos] = useState([]);
  // const [selectedVideo, setSelectedVideo] = useState(null);

  // useEffect(() => {
  //   const fetchVideos = async () => {
  //     const q = query(collection(db, 'video_presence'), orderBy('timestamp', 'desc'));
  //     const querySnapshot = await getDocs(q);
  //     const items = querySnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));
  //     setVideos(items);
  //   };

  //   fetchVideos();
  // }, []);

  // const closeModal = () => setSelectedVideo(null);

  return (
    <section className="py-12 justify-center">
      <h2 className="text-3xl font-heading font-semibold text-text-100 mb-6 text-center">VIDEO & AUDIO</h2>
      <br />

      {/* YouTube Shorts Section */}
      <div className="max-w-6xl mx-auto mb-12">
        <h3 className="text-2xl font-semibold text-center mb-4">YouTube Shorts</h3>
        <div className="relative bg-gray-900 rounded-lg shadow-md overflow-hidden">
          <iframe
            src="https://www.youtube.com/embed/videoseries?list=PLyT63PkS33YJ5KESDkeh3Iz0OX0ziNIjG"
            className="w-full h-96"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="YouTube Shorts"
          ></iframe>
        </div>
      </div>

      {/* YouTube Podcasts Section */}
      <div className="max-w-6xl mx-auto mb-12">
        <h3 className="text-2xl font-semibold text-center mb-4">YouTube Podcasts</h3>
        <div className="relative bg-gray-900 rounded-lg shadow-md overflow-hidden">
          <iframe
            src="https://www.youtube.com/embed/videoseries?list=PLyT63PkS33YJo46jFwiUSSc-QEiK0ErQ6"
            className="w-full h-96"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="YouTube Podcasts"
          ></iframe>
        </div>
      </div>

      {/* Spotify Podcasts Section */}
      <div className="max-w-6xl mx-auto mb-12">
        <h3 className="text-2xl font-semibold text-center mb-4">Spotify Podcasts</h3>
        <div className="relative bg-gray-900 rounded-lg shadow-md overflow-hidden">
          <iframe
            src="https://open.spotify.com/embed/playlist/7lthj9UoP9O5dqyaouTCFJ"
            className="w-full h-96"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="Spotify Podcasts"
          ></iframe>
        </div>
      </div>

      {/* Videos Grid Section 
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {videos.map((video) => (
          <motion.div
            key={video.id}
            className="relative bg-gray-900 rounded-lg shadow-md overflow-hidden cursor-pointer"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
            onClick={() => setSelectedVideo(video)}
          >
            <div className="relative aspect-w-16 aspect-h-9">
              <iframe
                src={`https://www.youtube.com/embed/${video.youtube_embed_code}`}
                className="absolute top-0 left-0 w-full h-full"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title={video.title}
              />
            </div>
          </motion.div>
        ))}
      </div>

      {/* Modal for Enlarged Video 
      {selectedVideo && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative w-4/5 bg-gray-900 rounded-lg overflow-hidden shadow-lg">
            <button
              className="absolute top-4 right-4 text-white bg-red-600 rounded-full p-2"
              onClick={closeModal}
            >
              ✕
            </button>
            <div className="relative aspect-w-16 aspect-h-9">
              <iframe
                src={`https://www.youtube.com/embed/${selectedVideo.youtube_embed_code}`}
                className="absolute top-0 left-0 w-full h-full"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title={selectedVideo.title}
              />
            </div>
            <div className="p-4">
              <h3 className="text-2xl font-semibold text-white text-center">{selectedVideo.title}</h3>
            </div>
          </div>
        </div>
      )}*/}
    </section>
  );
};

export default VideoPresence;
