import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
// import { useAuth } from '../context/AuthContext';

function Header() {
  // const { user, signIn, logOut } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsMenuOpen(false);
        setActiveDropdown(null);
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = (dropdownName) => {
    setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
  };

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-primary-100 bg-opacity-80 backdrop-filter backdrop-blur-lg' : 'bg-primary-100'}`}>
      <nav ref={navRef} className="container mx-auto flex flex-wrap justify-between items-center p-4">
        <Link to="/" className="flex items-center space-x-2">
          <img src={logo} alt="Logo" className="w-10 h-10" />
          <div className="flex flex-col">
            <span className="text-xl font-heading text-white">Dopamine for Thought</span>
            <span className="text-sm text-white">By Dr. Madhu Vamsi</span>
          </div>
        </Link>
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="lg:hidden text-white"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>
        <ul className={`${isMenuOpen ? 'block' : 'hidden'} lg:flex flex-col lg:flex-row w-full lg:w-auto mt-4 lg:mt-0 space-y-2 lg:space-y-0 lg:space-x-6`}>
          <NavItem to="/diagnostics" onClick={() => setIsMenuOpen(false)}>Diagnostics</NavItem>
          <NavItem to="/gift-a-friend" onClick={() => setIsMenuOpen(false)}>Gift a Friend</NavItem>
          <NavItem 
            to="/services" 
            dropdown={true}
            // isActive={activeDropdown === 'services'}
            onClick={() => toggleDropdown('services')}
          >
            Services
            <svg className="w-4 h-4 inline-block ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
            {activeDropdown === 'services' && (
              <ul className="mt-2 bg-primary-200 rounded-md">
                <DropdownItem to="/services/counselling" onClick={() => setIsMenuOpen(false)}>Counselling</DropdownItem>
                <DropdownItem to="/services/neuromodulation" onClick={() => setIsMenuOpen(false)}>Neuromodulation</DropdownItem>
                <DropdownItem to="/services/medications" onClick={() => setIsMenuOpen(false)}>Medications</DropdownItem>
                <DropdownItem to="/services/lab" onClick={() => setIsMenuOpen(false)}>Lab</DropdownItem>
              </ul>
            )}
          </NavItem>
          <NavItem to="/for-business" onClick={() => setIsMenuOpen(false)}>For Business</NavItem>
          <NavItem to="/Squad" onClick={() => setIsMenuOpen(false)}>Squad!</NavItem>
          <NavItem to="/media" onClick={() => setIsMenuOpen(false)}>Media</NavItem>
          <NavItem to="/blog" onClick={() => setIsMenuOpen(false)}>Blogs</NavItem>
          {/* <NavItem to="/blog" onClick={() => setIsMenuOpen(false)}>Blog</NavItem>
          {user && user.email === process.env.REACT_APP_ADMIN_EMAIL && (
            <NavItem to="/blog/admin" onClick={() => setIsMenuOpen(false)}>Admin</NavItem>
          )}
          {user ? (
          <button 
            onClick={() => {
              logOut();
              setIsMenuOpen(false);
            }} 
            className="bg-red-500 text-white px-4 py-2 rounded ml-4"
          >
            Sign Out
          </button>
        ) : (
          <button 
            onClick={() => {
              signIn();
              setIsMenuOpen(false);
            }} 
            className="bg-green-500 text-white px-4 py-2 rounded ml-4"
          >
            Sign In
          </button>
        )} */}
        </ul>
      </nav>
    </header>
  );
}

function NavItem({ to, children, dropdown, isActive, onClick }) {
  return (
    <li className={`relative ${dropdown ? 'hover:bg-primary-200 rounded-md' : ''}`}>
      <Link
        to={to}
        className="block py-2 px-3 text-sm lg:text-base transition-colors duration-300 text-white hover:text-accent-200"
        onClick={onClick}
      >
        {children}
      </Link>
    </li>
  );
}

function DropdownItem({ to, children, onClick }) {
  return (
    <li>
      <Link
        to={to}
        className="block py-2 px-4 text-sm whitespace-nowrap hover:bg-primary-300 rounded-md transition-colors duration-300 text-white"
        onClick={onClick}
      >
        {children}
      </Link>
    </li>
  );
}

export default Header;