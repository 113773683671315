import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { db } from '../firebase';
import { doc, getDoc } from "firebase/firestore";
import { useParams } from 'react-router-dom';

const BlogPost = () => {
  const [blog, setBlog] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const blogRef = doc(db, "blogs", id);
        const blogSnapshot = await getDoc(blogRef);
        if (blogSnapshot.exists()) {
          setBlog(blogSnapshot.data());
          console.log(blogSnapshot.data());
        } else {
          console.warn("Blog not found!");
        }
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    };

    fetchBlog();
  }, [id]);

  if (!blog) {
    return <div>Loading...</div>;
  }

  const renderContent = (content) => {
    return content.map((section, index) => (
      <div key={index} className="mb-12">
        {/* Render Heading */}
        <h2 className="text-2xl font-bold mt-8 mb-4">{section.heading}</h2>
        
        {/* Render Paragraphs under Heading */}
        {section.paragraphs.map((paragraph, i) => (
          <p key={i} className="mb-4">{paragraph}</p>
        ))}

        {/* Render Subheadings */}
        {section.subheadings.map((sub, i) => (
          <div key={i} className="mb-6">
            <h3 className="text-xl font-semibold mt-6 mb-3">{sub.subheading}</h3>
            {sub.paragraphs.map((paragraph, j) => (
              <p key={j} className="mb-4">{paragraph}</p>
            ))}
            {sub.bullet_points.length > 0 && (
              <ul className="list-disc list-inside mb-4">
                {sub.bullet_points.map((point, k) => (
                  <li key={k}>{point}</li>
                ))}
              </ul>
            )}
            {sub.images.map((image, k) => (
              <div key={k} className="my-4">
                <img
                  src={image}
                  alt={`Blog subheading illustration ${k + 1}`}
                  className="w-full max-w-2xl h-auto object-cover mx-auto rounded"
                />
              </div>
            ))}
          </div>
        ))}

        {/* Render Bullet Points under Heading */}
        {section.bullet_points.length > 0 && (
          <ul className="list-disc list-inside mb-6">
            {section.bullet_points.map((point, i) => (
              <li key={i}>{point}</li>
            ))}
          </ul>
        )}

        {/* Render Images under Heading */}
        {section.images.map((image, i) => (
          <div key={i} className="my-4">
            <img
              src={image}
              alt={`Blog section illustration ${i + 1}`}
              className="w-full max-w-2xl h-auto object-cover mx-auto rounded"
            />
          </div>
        ))}
      </div>
    ));
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto py-12"
    >
      {/* Blog Title */}
      <h1 className="text-heading font-heading text-4xl mb-4">{blog.title}</h1>
      
      {/* Blog Summary */}
      <p className="text-lg text-gray-700 mb-6">{blog.summary}</p>
      
      {/* Blog Tags */}
      <div className="mb-6">
        {blog.tags.map((tag, index) => (
          <span
            key={index}
            className="inline-block bg-blue-100 text-blue-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded"
          >
            #{tag}
          </span>
        ))}
      </div>
      
      {/* Render Content Hierarchically */}
      {renderContent(blog.content)}

      {/* Blog Metadata */}
      {/* <div className="mt-8 text-sm text-gray-600">
        <p>Written by: <span className="font-medium">{blog.author}</span></p>
        <p>Published on: {new Date(blog.createdAt).toLocaleDateString()}</p>
      </div> */}
    </motion.div>
  );
};

export default BlogPost;
