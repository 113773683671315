import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { db } from '../firebase';
import { collection, getDocs } from "firebase/firestore";
import { Link } from 'react-router-dom';

const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogCollection = collection(db, "blogs");
        const snapshot = await getDocs(blogCollection);
        if (snapshot.empty) {
          console.warn("No blogs found!");
          setBlogs([]);
          return;
        }

        // Map blog data to include id
        const blogData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setBlogs(blogData);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto py-12"
    >
      <h1 className="text-heading font-heading text-4xl mb-6">All Blogs</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {blogs.map((blog) => (
          <Link to={`/blog/${blog.id}`} key={blog.id}>
            <motion.div
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="bg-white shadow-md rounded-lg overflow-hidden"
            >
              <img
                src={blog.imageSuggestions || "/placeholder.jpg"} // Fallback to a placeholder image
                alt={blog.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h2 className="text-heading font-heading text-xl mb-2">{blog.title}</h2>
                <p className="text-text-100 line-clamp-3">{blog.summary}</p>
              </div>
            </motion.div>
          </Link>
        ))}
      </div>
    </motion.div>
  );
};

export default Blog;
